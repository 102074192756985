import { graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';
import SEO from '../components/SEO';

const SinglePersonPage = ({ data: { person } }) => (
  <>
    <SEO
      title={`Slicemaster ${person.name}`}
      image={person?.image?.asset?.fluid?.src}
    />
    <Img fluid={person.image.asset.fluid} alt={person.name} />
    <div className="center">
      <h2 className="mark">{person.name}</h2>
      <p>{person.description}</p>
    </div>
  </>
);
export default SinglePersonPage;

export const query = graphql`
  query SinglePersonQuery($slug: String!) {
    person: sanityPerson(slug: { current: { eq: $slug } }) {
      name
      id
      description
      slug {
        current
      }
      image {
        asset {
          fluid(maxHeight: 1000, maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
